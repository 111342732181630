.ldsRing {
    display: inline-block;
    position: relative;
}

.ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ldsRing {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}